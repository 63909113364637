.portfolio__container {
    display: flex;
    flex-direction: column;
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem 1rem 1rem;
}

.swiper-button-next {
    right: 20px!important;
    color: var(--color-light,var(--color-white))!important;
}

.swiper-button-prev {
    left: 20px!important;
    color: var(--color-light,var(--color-white))!important;
}

.portfolio__container-image {
    display: block;
}

.portfolio__item-image {
    display: block;
    height: 10rem;
}

#portfolio-image {
    margin: 0 auto;
    object-fit: cover;
    border-radius: 1.4rem;
    background-color: white;
    object-fit: contain;
}

.project_card {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background: var(--color-bg-variant);
    padding: 3.6rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
    width: 100%;
    max-width: 40rem;
    box-shadow: 10px 5px 5px 5px var(--color-primary-variant);
}

.portfolio__item {
    background: var(--color-bg-variant);
    height: 15rem;
    width: 20rem;
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
    justify-content: center;
    box-shadow: 10px 5px 5px 5px var(--color-primary-variant);
}

.portfolio__item h3 {
    margin: .5rem 0 0 0;
}

.portfolio__item p {
    margin: 0 0 .5rem 0;
}

.portfolio__item-title {
    text-align: center;
}

.portfolio__item-cta {
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin-top: 1rem;
}

.portfolio__item-description {
    font-style: italic;
    font-size: 16px;
}

.project-link {
    color: var(--color-white);
}

.project-link:hover {
    color: var(--color-bg-variant);
    background-color: var(--color-light);
}

.skills__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
    margin-block: .5rem;
}

.skills {
    justify-content: center;
    background-color: var(--color-primary-variant);
    color: var(--color-white);
    border-radius: 15px;
    padding: 0 1rem;
    font-size: 14px;
    border: .5px solid var(--color-white);
}

/* =========== MEDIA QUERIES (MEDIUM DEVICES) ============== */
@media screen and (max-width: 1024px) {
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

/* =========== MEDIA QUERIES (SMALL DEVICES) ============== */
@media screen and (max-width: 600px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .project_card {
        padding: 1.5rem;
    }

    .swiper-button-next {
        margin-right: -5px;
    }
    
    .swiper-button-prev {
        margin-left: -5px;
    }

    .skills__container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: .75rem;
    }
}