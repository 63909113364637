.container.contact__container {
    display: grid;
    margin: 0 auto;
}

.contact__options {
    display: flex;
    gap: 2rem;
    justify-content: center;
}

.contact__option {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
    width: 100%;
    box-shadow: 10px 5px 5px 5px var(--color-primary-variant);
}

.contact__option-icon {
    font-size: 2rem;
}

.contact__option-icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-primary-variant);
    width: 3rem;
    height: 3rem;
    border: .5px solid var(--color-white);
    border-radius: 50%;
    margin: 0 auto;
}

.contact__option a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 16px;
}

.profile-name {
    color: var(--color-bg-variant);
}
/* =============== FORM ================== */

.contact__form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input,
textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary-variant);
    resize: none;
    color: var(--color-white);
    font-family: 'Poppins', sans-serif;

}

.btn {
    padding: 1rem;
    font-family: 'Poppins', sans-serif;
}

::placeholder {
    color: var(--color-light)
}


/* =========== MEDIA QUERIES (MEDIUM DEVICES) ============== */
@media screen and (max-width: 1024px) {
    .container.contact__container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .contact__options {
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
        justify-content: center;
        width: 100%;
    }
}

/* =========== MEDIA QUERIES (SMALL DEVICES) ============== */
@media screen and (max-width: 600px) {
    .container.contact__container {
        display: flex;
        flex-direction: column;
        padding: 0 1rem;
    }

    .contact__options {
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
        justify-content: center;
        width: 100%;
    }
}