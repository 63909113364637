.experience__container {
    display: grid;
    gap: 2rem;
    align-self: center;
}

.experience__skills {
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(160px, 1fr) );
    gap: .1rem;
    background: var(--color-bg-variant);
    padding: 2.5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
    box-shadow: 10px 5px 5px 5px var(--color-primary-variant);
}

.experience__details {
    display: flex;
    align-items: center;
    justify-content: center;
}

.experience__details-icon {
    font-size: 1.5rem;
    color: var(--color-primary);
    margin-top: .5rem;
    margin-left: 1.5rem;
}

.experience__content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(115px, 1fr));
    gap: 2rem;
    padding: .75rem .5rem;
}

.heading {
    margin-block: 2rem;
    text-align: center;
}

.experience__container_trio {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
}


/* =========== MEDIA QUERIES (MEDIUM DEVICES) ============== */
@media screen and (max-width: 1024px) {
    .experience__content {
        padding: .75rem .5rem;
    }

    .experience__container_trio {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0;
    }
}

/* =========== MEDIA QUERIES (SMALL DEVICES) ============== */
@media screen and (max-width: 600px) {
    .experience__container {
        gap: 1rem;
        padding: 0 1rem;
    }

    .experience__skills {
        width: 100%;
        padding: 2rem 1rem;
        grid-template-columns: repeat( auto-fit, minmax(130px, 1fr) );
    }

    .heading {
    margin-block: 1rem;
    text-align: center;
    }
}