.about__container {
    display: grid;
}

.about__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    border: 1px solid transparent;
    transition: var(--transition);
    width: 100%;
    text-align: center;
    box-shadow: 10px 5px 5px 5px var(--color-primary-variant);
    gap: 2rem;
    align-items: center;
}

.about__content p {
    margin-bottom: 1.5rem;
    color: var(--color-light);
    text-align: start;
}

.about_text_button {
    margin-block: 1.5rem;
}


/* =========== MEDIA QUERIES (MEDIUM DEVICES) ============== */
@media screen and (max-width: 1024px) {
    .about__container {
        grid-template-columns: 1fr;
        gap: 0;
    }

    .about__content {
        gap: 2rem;
    }

    .about__content p {
        margin-bottom: 1rem;
    }

    .about_text_button {
        margin-block: 1rem;
    }
}

img {
    height: 100%;
}

.me {
    background: linear-gradient(var(--color-primary), transparent);
    position: relative;
    overflow: hidden;
    height: 30rem;
    border-radius: 12rem;
}

/* =========== MEDIA QUERIES (SMALL DEVICES) ============== */
@media screen and (max-width: 834px) {
    .about__content {
        text-align: center;
        display: flex;
        flex-direction: column;
    }
    .about__content {
        gap: 0;
    }
    .about_text_button {
        margin-bottom: 0;
    }
}

@media screen and (max-width: 600px) {
    .about__content p {
        margin-bottom: 1rem;
     }
}